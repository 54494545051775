import React from "react"
import Layout from "components/Layout"

const NotFoundPage = () => (
  <Layout>
    <h1>NOT FOUND</h1>
    <p>How did you even get here?.</p>
  </Layout>
)

export default NotFoundPage
